const PERMISSION_KEY = "permissions";

export const getPermission = () => {
  let _permissions = window.localStorage.getItem(PERMISSION_KEY);
  if (_permissions){
    _permissions = _permissions.split(',');
  }else {
    _permissions = [];
  }
  return _permissions;
};

export const savePermission = permission => {
  window.localStorage.setItem(PERMISSION_KEY, permission);
};

export const destroyPermission = () => {
  window.localStorage.removeItem(PERMISSION_KEY);
};

export default { getPermission, savePermission, destroyPermission };
