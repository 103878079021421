<template>
    <div>
        <b-dropdown right :text="$t('export')" class="mr-2 ml-2">

            <button class="dropdown-item" @click="startDownload('excel')">
                <i class="la la-file-excel"></i>{{$t('excel')}}
            </button>
            <button class="dropdown-item" @click="startDownload('print')">
                <span><i class="fa fa-print"></i> <span>{{$t('print')}}</span></span>
            </button>




            <button @click="showModal" class="dropdown-item">
                <span><span>{{$t('custom_export')}}</span></span>
            </button>

        </b-dropdown>
        <b-modal ref="modalExport" hide-footer :title="$t('custom_export')">
            <div class="row">
                <div class="col-md-12 mb-7">
                    <b-form-checkbox v-model="select_all" :key="'select_all'">{{$t('select_all')}}</b-form-checkbox>
                </div>
                <div class="col-md-6 mb-3" v-for="(row, index) in fields" :key="index">
                    <b-form-checkbox v-model="customFields" @change="selectValue" :value="row" :key="row">{{index}}</b-form-checkbox>
                </div>
            </div>


            <div class="card-footer pl-0 pr-0 pb-0">
                <div class="row">
                    <div class="col-lg-12">

                        <button class="btn btn-primary mt-2 btn-sm" @click="startDownload('excel')">
                            <i class="la la-file-excel"></i>{{$t('excel')}}
                        </button>

                        <button @click="startDownload('print')" class="btn btn-primary mt-2 mr-1 btn-sm">
                            <span><i class="la la-file-pdf" style="font-size: 22px;"></i> <span>{{$t('print')}}</span></span>
                        </button>
                        <b-button class="mt-2 mr-1" variant="secondary" @click="hideModal">{{$t('close')}}</b-button>

                    </div>
                </div>
            </div>
        </b-modal>



        <div class="export-container">
            <div class="section-for-export" id="sectionForExport">
                <table class="table table-bordered">
                    <thead>
                    <tr>
                        <th v-for="(_th, index) in customFields" :key="index">
                          {{selectedFields[_th]}}
                        </th>

                    </tr>
                    </thead>
                    <tbody>

                    <template v-if="dataList">
                        <tr v-for="(row, index) in dataList" :key="index">
                          <template v-for="(td, index_2) in customFields">
                            <td :key="index +'_'+ index_2">{{row[td] ? row[td] : ''}}</td>
                          </template>

                        </tr>
                    </template>


                    </tbody>
                </table>
            </div>
        </div>
    </div>

</template>

<script>
    import ApiService from "@/core/services/api.service";
    import TableToExcel from "@linways/table-to-excel";

    export default {
        name: "CustomExportData",
        props: ['route', 'fields', 'filters', 'dataList', 'fileName'],
        data() {
            return {
                selectedFields: {},
                customFields: [],
                select_all: true,
            }
        },
        watch: {
            select_all: function (val) {
                if (val) {
                  this.selectedFields = {};
                  this.customFields = [];
                    let _keys = Object.keys(this.fields);
                    _keys.forEach((index) => {
                        let _val = this.fields[index];
                      this.selectedFields[_val] = index;
                      this.customFields.push(_val);
                    });
                    // Object.assign(this.customFields, this.fields);
                } else {
                    this.selectedFields = {};
                    this.customFields = [];
                }



            }
        },
        methods: {
            printData() {

                let divToPrint = document.getElementById('sectionForExport');
                let divTitleToPrint = this.fileName;
                let newWin = window.open("");
                newWin.document.write("<link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css'>" + divTitleToPrint + divToPrint.outerHTML);
                setTimeout(() => {
                    newWin.print();
                    newWin.close();
                }, 100)
            },

            convertTableToExcelReport() {

                TableToExcel.convert(document.getElementById('sectionForExport'), {
                    name: this.fileName + '.xlsx',
                    sheet: {
                        name: 'Sheet 1'
                    }
                });
            },





            /**
             * function to get data without pagination
             * to export data
             * @returns {Promise<*>}
             */

            async fetchData() {
                await ApiService.query(this.route, { is_export: 1, ...this.filters}).then((response)=>{
                  this.dataList = response.data.data;
                });
            },



            startDownload(type) {
              if (this.route){
                let promise = this.fetchData();
                Promise.all([promise]).then(()=>{
                  if (type == 'print'){
                    this.printData();
                  }else if(type == 'excel') {
                    this.convertTableToExcelReport();
                  }
                })
              }else {
                if (type == 'print'){
                  this.printData();
                }else if(type == 'excel') {
                  this.convertTableToExcelReport();
                }
              }
            },
            finishDownload() {
            },
            showModal() {
                this.$refs['modalExport'].show()
            },
            hideModal() {
                this.$refs['modalExport'].hide();
            },
            toggleModal() {
                this.$refs['modalExport'].toggle('#toggle-btn');
            },
            selectValue() {

              this.selectedFields = {};
              let _custom_fields = {};

              let _keys = Object.keys(this.fields);
              _keys.forEach((index) => {
                let _val = this.fields[index];
                _custom_fields[_val] = index;
              });

              this.customFields.forEach((row) => {
                let _val = _custom_fields[row];
                this.selectedFields[row] = _val;
              });


            },
            selectAll() {
                this.select_all = !this.select_all;
            }
        },
      created() {
        this.selectedFields = {};
        this.customFields = [];

        let _keys = Object.keys(this.fields);
        _keys.forEach((index) => {
          let _val = this.fields[index];
          this.selectedFields[_val] = index;

          this.customFields.push(_val);
        });

      }
    }
</script>

<style scoped>
  .export-container{
    width: 0.0001px;
    height: 0.0001px;
    overflow: hidden;
  }
</style>