import { initializeApp } from 'firebase/app';
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyCHN4FsmFrZSFbx7EACwXjLlDTyGxtQMac",
    authDomain: "asadtest-a1f6c.firebaseapp.com",
    databaseURL: "https://asadtest-a1f6c.firebaseio.com",
    projectId: "asadtest-a1f6c",
    storageBucket: "asadtest-a1f6c.appspot.com",
    messagingSenderId: "739968806231",
    appId: "1:739968806231:web:2c0cc6b116b66ac2d52212"
};
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
// window.firebase_messaging = getMessaging(app);
window.firebase_messaging = messaging;




// onMessage(messaging, (payload) => {
//     console.log(payload.data);
// });