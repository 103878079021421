<template>
    <div>
        <div style="width: 260px">
            <input :id="innerId" type="file" class="inputfile" v-on:change="uploadFile" :disabled="disableLoadingButton">
            <label :for="innerId" class="btn btn-primary" :disabled="disableLoadingButton">
                <i class="fa fa-upload"></i>
                {{placeholder}}
                <span v-if="disableLoadingButton"><i class="fa fa-spinner fa-pulse fa-fw"></i></span>
            </label>
        </div>

        <div class="error" v-if="validation && validation.file" style="color: red">{{ validation.file[0] }}</div>
        <div class="error" v-if="validation && validation.extensions" style="color: red">{{ validation.extensions }}</div>
        <div v-if="file.path && usePreview" style="display: flex;margin-top: 15px;width: 140px;">
            <a :href="file.path" target="_blank">
                <img :src="file.path" style="width: 50px;" alt="">
                <p>
                    {{ $t('link')}}
                </p>
            </a>
            <button @click="deleteFile()"
                    class="btn btn-link m-btn m-btn--icon btn-sm m-btn--icon-only m-btn--pill m-btn--air"
                    title="Delete">
                <i class="fa fa-trash"></i>
            </button>
        </div>
    </div>
</template>

<script>
    import ApiService from "@/core/services/api.service";

    export default {
        data() {
            return {
                disableLoadingButton: false,
                validation: null,
                file: {
                    path: null,
                    name: null
                },

                valid: {
                    extensions: null,
                },
            }
        },
        watch: {},
        props: {
            innerId: null,
            upload: null,
            placeholder: null,

            typeExtension: null,
            valueprop: null,
            startLink: null,
            usePreview: {
                type: Boolean,
                default: true
            },
        },
        mounted() {
            setTimeout(()=>{
                this.file.path = this.valueprop.path;
                this.file.name = this.valueprop.name;
            },1000)

        },

        methods: {
            uploadFile(e) {
                var file = e.target.files[0];
                document.getElementById(this.innerId).value = "";
                var file_name = file.name;

                var extension = file_name.substr((file_name.lastIndexOf('.') + 1)).toLowerCase();
                // var extensions = ["jpg", "jpeg", "png", "pdf", "doc", 'docx'];


                var extensions = this.typeExtension ? this.typeExtension : ["jpg", "jpeg", "png", "pdf", "doc", 'docx'];

                if (!extensions || extensions.indexOf(extension) < 0) {
                    this.validation = {
                        extensions: this.$t('please_upload_valid_file')
                    };
                    return false;
                }


                var formData = new FormData();
                formData.append('file', file);
                formData.append('upload', this.upload);
                this.disableLoadingButton = true;

                ApiService.post((this.startLink?'/'+this.startLink:'')+'/uploads/upload-file', formData)
                    .then(response => {
                        this.disableLoadingButton = false;
                        this.valid = null;
                        if (this.validation && this.validation.extensions) {
                            this.validation.extensions = null;
                        } else {
                            this.validation = {
                                extensions: null
                            };
                        }
                        var file = {
                            path: response.data.path,
                            name: response.data.name,
                            pathDB: response.data.pathDB,
                            upload_date_time: response.data.upload_date_time,
                        };
                        this.$emit('file', file);
                        this.file = file;
                        //this.value.name = file.name;
                        //this.value.path = file.path;
                    })
                    .catch(function (err) {
                        this.validation = err.response.data.errors;
                        this.disableLoadingButton = false;
                    }.bind(this));
            },
            deleteFile() {

                var file = {
                    path: null,
                    name: null,
                    pathDB: null,
                };
                this.file = file;

                this.$emit('file', file);
            }
        }
    }
</script>

<style>
    .inputfile {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }
</style>

