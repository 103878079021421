import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/jwt.service";
import I18nService from "@/core/services/i18n.service";
/**
 * Service to call HTTP request via Axios
 */

Vue.prototype.$postStatus = true;

const addPageLoader = ()=>{
    document.body.classList.add("page-loading");
}
const removePageLoader = ()=>{
    setTimeout(() => {
        document.body.classList.remove("page-loading");
    }, 2000);
}
const ApiService = {
    init() {
        axios.interceptors.request.use(function (config) {
            addPageLoader();
            return config;
        }, function (error) {
            removePageLoader();
            return Promise.reject(error);
        });
        axios.interceptors.response.use(function (response) {
            removePageLoader();
            return response;
        }, function (error) {
            removePageLoader();
            return Promise.reject(error);
        });

        // console.log(process.env.VUE_APP_URL);
        Vue.use(VueAxios, axios);
        Vue.axios.defaults.baseURL = process.env.VUE_APP_URL;


    },

    /**
     * Set the default HTTP request headers
     */
    setHeader() {
        Vue.axios.defaults.headers.common["Authorization"] = `Bearer ${JwtService.getToken()}`;
        Vue.axios.defaults.headers.common["Accept-Language"] = `${I18nService.getActiveLanguage()}`;
    },

    getBaseURL(){
        return Vue.axios.defaults.baseURL;
    },
    
    query(resource, params) {
        return new Promise((resolve, reject) => {
            return Vue.axios.get(resource, {params: {...params}}).then((response) => {
                resolve(response);
                setTimeout(() => {
                    Vue.prototype.$postStatus = true;
                }, 2000);
            })
            // .catch((error) => {
            //     reject(error);
            //     setTimeout(() => {
            //         Vue.prototype.$postStatus = true;
            //     }, 2000);
            // });
        });
            //     .catch(error => {
            //     // console.log(error);
            //     throw new Error(`[KT] ApiService ${error}`);
            // });
    },

    /**
     * Send the GET HTTP request
     * @param resource
     * @param params
     * @returns {*}
     */
    get(resource, params) {

        return new Promise((resolve, reject) => {
            return Vue.axios.get(`${resource}`, params).then((response) => {
                resolve(response);
                setTimeout(() => {
                    Vue.prototype.$postStatus = true;
                }, 2000);
            })
            // .catch((error) => {
            //     reject(error);
            //     setTimeout(() => {
            //         Vue.prototype.$postStatus = true;
            //     }, 2000);
            // });
        });
        //     .catch(() => {
        //     this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
        //     this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
        // });
    },

    /**
     * Set the POST HTTP request
     * @param resource
     * @param params
     * @returns {*}
     */
    post(resource, params) {
        if (Vue.prototype.$postStatus) {
            Vue.prototype.$postStatus = false;
            return new Promise((resolve, reject) => {
                return Vue.axios.post(`${resource}`, params).then((response) => {
                    resolve(response);
                    // setTimeout(() => {
                    //     Vue.prototype.$postStatus = true;
                    // }, 2000);
                }).catch((error) => {
                    reject(error);
                    setTimeout(() => {
                        Vue.prototype.$postStatus = true;
                    }, 2000);
                });
            });
        }else {
            return new Promise((resolve, reject)=>{
                resolve([]);
            });
        }
    },

    /**
     * Send the UPDATE HTTP request
     * @param resource
     * @param slug
     * @param params
     * @returns {IDBRequest<IDBValidKey> | Promise<void>}
     */
    update(resource, slug, params) {
        if (slug) {
            slug = '/' + slug;
        }
        if (Vue.prototype.$postStatus) {
            Vue.prototype.$postStatus = false;
            return new Promise((resolve, reject) => {
                return Vue.axios.put(`${resource}${slug}`, params).then((response) => {
                    resolve(response);
                    // setTimeout(() => {
                    //     Vue.prototype.$postStatus = true;
                    // }, 2000);
                }).catch((error) => {
                    reject(error);
                    setTimeout(() => {
                        Vue.prototype.$postStatus = true;
                    }, 2000);
                });
            });
        }else {
            return new Promise((resolve, reject)=>{
                resolve([]);
            });
        }
    },

    /**
     * Send the PUT HTTP request
     * @param resource
     * @param params
     * @returns {IDBRequest<IDBValidKey> | Promise<void>}
     */
    put(resource, params) {
        if (Vue.prototype.$postStatus) {
            Vue.prototype.$postStatus = false;
            return new Promise((resolve, reject) => {
                return Vue.axios.put(`${resource}`, params).then((response) => {
                    resolve(response);
                    // setTimeout(() => {
                    //     Vue.prototype.$postStatus = true;
                    // }, 2000);
                }).catch((error) => {
                    reject(error);
                    setTimeout(() => {
                        Vue.prototype.$postStatus = true;
                    }, 2000);
                });
            });
        }else {
            return new Promise((resolve, reject)=>{
                resolve([]);
            });
        }
    },
    /**
     * Send the PATCH HTTP request
     * @param resource
     * @param params
     * @returns {IDBRequest<IDBValidKey> | Promise<void>}
     */
    patch(resource, params) {
        if (Vue.prototype.$postStatus) {
            Vue.prototype.$postStatus = false;
            return new Promise((resolve, reject) => {
                return Vue.axios.patch(`${resource}`, params).then((response) => {
                    resolve(response);
                    // setTimeout(() => {
                    //     Vue.prototype.$postStatus = true;
                    // }, 2000);
                }).catch((error) => {
                    reject(error);
                    setTimeout(() => {
                        Vue.prototype.$postStatus = true;
                    }, 2000);
                });
            });
        }else {
            return new Promise((resolve, reject)=>{
                resolve([]);
            });
        }
    },

    /**
     * Send the DELETE HTTP request
     * @param resource
     * @param params
     * @returns {Promise<unknown>}
     */
    delete(resource, params) {
        if (Vue.prototype.$postStatus) {
            Vue.prototype.$postStatus = false;
            return new Promise((resolve, reject) => {
                return Vue.axios.delete(resource, params).then((response) => {
                    resolve(response);
                    // setTimeout(() => {
                    //     Vue.prototype.$postStatus = true;
                    // }, 2000);
                }).catch((error) => {
                    reject(error);
                    setTimeout(() => {
                        Vue.prototype.$postStatus = true;
                    }, 2000);
                });
            });
        }else {
            return new Promise((resolve, reject)=>{
                resolve([]);
            });
        }
        //     .catch(error => {
        //     // console.log(error);
        //     throw new Error(`[RWV] ApiService ${error}`);
        // });
    }
};

export default ApiService;