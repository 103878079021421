import Vue from "vue";
import SweetAlert2 from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
// Vue.use(SweetAlert2);

const _timer = 2500;

Vue.prototype.$confirmAlert = function(title = '', execFunc, paramCallback) {
    SweetAlert2.fire({
        title: title ? title : this.$t('Do_you_want_delete_it'),
        showCancelButton: true,
        confirmButtonText: this.$t('Confirm'),
        cancelButtonText: this.$t('Cancel'),
    }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
            execFunc(paramCallback);
        }
    });
}
Vue.prototype.$successAlert = function(title = '', timer = _timer) {
    SweetAlert2.fire({
        icon: 'success',
        title: title,
        // text: title,
        showConfirmButton: false,
        timer: timer
    });
}
Vue.prototype.$warningAlert = function(error = '', timer = _timer) {
    SweetAlert2.fire({
        icon: 'warning',
        // text: '',
        title: error.response ? error.response.data.message : '', //(typeof (error) == 'string') ? error : convertCustomErrorObjectForSwalToString(error.response.data),
        showConfirmButton: true,
        cancelButtonText: this.$t('ok'),
        timer: timer
    });
}
Vue.prototype.$errorAlert = function(error = '', timer = _timer) {
    SweetAlert2.fire({
        icon: 'error',
        // text: '',
        title: error.response ? error.response.data.message : '', //(typeof (error) == 'string') ? error : convertCustomErrorObjectForSwalToString(error.response.data),
        showConfirmButton: false,
        timer: timer
    });
}
Vue.prototype.$warningMessage = function(message = '', timer = _timer) {
        SweetAlert2.fire({
            icon: 'warning',
            // text: '',
            title: message ? message : '',
            showConfirmButton: true,
            cancelButtonText: this.$t('ok'),
            timer: timer
        });
    }
    // function convertCustomErrorObjectForSwalToString(response) {
    //     var returned_string = '';
    //
    //     if (response.message != undefined && response.errors != undefined) // custom error from helpers
    //     {
    //         if (response.errors instanceof Object) {
    //             $.each(response.errors, function (index, message) { // response is number of errors
    //                 returned_string = returned_string + message + "\n";
    //             });
    //         } else { // response is single text message
    //             returned_string = response.message + "\n";
    //         }
    //     } else { // laravel error
    //         $.each(response, function (index, message) {
    //             returned_string = returned_string + message + "\n";
    //         });
    //     }
    //
    //     return returned_string;
    // }
    // function errorHandler(errors) {
    //     let obj = {};
    //     if (errors){
    //         let _keys = Object.keys(errors);
    //         _keys.map((key)=>{
    //             obj[key.replace('.','_')] = errors[key];
    //         });
    //     }
    //
    //     return obj;
    // }