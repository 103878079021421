const i18nService = {
  defaultLanguage: "ar",

  languages: [
    {
      lang: "ar",
      name: "Arabic",
      flag: process.env.BASE_URL + "media/svg/flags/133-saudi-arabia.svg"
    },

    {
      lang: "en",
      name: "English",
      flag: process.env.BASE_URL + "media/svg/flags/226-united-states.svg"
    },
    {
      lang: "es",
      name: "Spanish",
      flag: process.env.BASE_URL + "media/svg/flags/128-spain.svg"
    },

    // {
    //   lang: "ch",
    //   name: "Mandarin",
    //   flag: process.env.BASE_URL + "media/svg/flags/034-china.svg"
    // },
    // {
    //   lang: "jp",
    //   name: "Japanese",
    //   flag: process.env.BASE_URL + "media/svg/flags/063-japan.svg"
    // },
    // {
    //   lang: "de",
    //   name: "German",
    //   flag: process.env.BASE_URL + "media/svg/flags/162-germany.svg"
    // },
    // {
    //   lang: "fr",
    //   name: "French",
    //   flag: process.env.BASE_URL + "media/svg/flags/195-france.svg"
    // }
  ],

  /**
   * Keep the active language in the localStorage
   * @param lang
   */
  setActiveLanguage(lang) {
    localStorage.setItem("language", lang);
  },

  /**
   * Get the current active language
   * @returns {string | string}
   */
  getActiveLanguage() {
    let _lang = localStorage.getItem("language") || this.defaultLanguage
    let el = document.querySelector('html');
    el.setAttribute('lang', _lang);
    setTimeout(()=>{
      if(_lang == 'ar'){
        //lang="en" dir="rtl" direction="rtl" style="direction: rtl"
        el.setAttribute('dir', 'rtl');
        el.setAttribute('direction', 'rtl');
        el.style.direction = 'rtl';
      }else {
        el.setAttribute('dir', 'ltr');
        el.setAttribute('direction', 'ltr');
        el.style.direction = 'ltr';
      }

    }, 10);
    return _lang;
  }
};

export default i18nService;
