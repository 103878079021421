import ApiService from "@/core/services/api.service";

// action types
const NOTIFICATION_MAIN_ROUTE = "/notifications";
export const GET_NOTIFICATIONS = "getNotification";
export const SET_NOTIFICATIONS = "setNotification";
export const GET_NOTIFICATIONS_COUNT = "getNotificationCount";
export const SET_NOTIFICATIONS_COUNT = "setNotificationCount";
export const SET_ERROR = "setError";

const state = {
  list: [],
  count: 0,
};

const getters = {
  getList(state) {
    return state.list;
  },
  getCount(state) {
    return state.count;
  },

};

const actions = {
  [GET_NOTIFICATIONS](context) {
    // new Promise(resolve => {
      ApiService.get(NOTIFICATION_MAIN_ROUTE+"/list")
        .then(({ data }) => {
          context.commit(SET_NOTIFICATIONS, data.data.data);
          // resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    // });
  },
  [GET_NOTIFICATIONS_COUNT](context) {
    // return new Promise(resolve => {
      ApiService.get(NOTIFICATION_MAIN_ROUTE+"/get_not_read_count")
        .then(({ data }) => {
          context.commit(SET_NOTIFICATIONS_COUNT, data.data.count);
          // resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    // });
  },

};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },

  [SET_NOTIFICATIONS](state, list) {
    state.list = list;
  },
  [SET_NOTIFICATIONS_COUNT](state, count) {
    state.count = count;
  },

};

export default {
  state,
  actions,
  mutations,
  getters
};
