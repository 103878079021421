import ApiService from "@/core/services/api.service";

// action types
export const UPDATE_PERSONAL_INFO = "updateUserPersonalInfo";
export const UPDATE_ACCOUNT_INFO = "updateUserAccountInfo";

// mutation types
export const SET_PERSONAL_INFO = "setPersonalInfo";
export const SET_ACCOUNT_INFO = "setAccountInfo";
export const SET_ERROR = "setError";

const state = {
  user_personal_info: {
    id: "",
    photo: "",
    name: "",
    surname: "",
    company_name: "",
    job: "",
    email: "",
    phone: "",
    company_site: ""
  },
  user_account_info: {
    username: "nick84",
    email: "nick.watson@loop.com",
    language: "English",
    time_zone: "(GMT-11:00) Midway Island",
    communication: {
      email: true,
      sms: true,
      phone: false
    },
    verification: true
  }
};

const getters = {
  currentUserPersonalInfo(state) {
    return state.user_personal_info;
  },

  currentUserAccountInfo(state) {
    return state.user_account_info;
  },

  currentUserPhoto(state) {
    return state.user_personal_info.photo;
  }
};

const actions = {
  [UPDATE_PERSONAL_INFO](context) {
    return new Promise(resolve => {
      ApiService.get("getUserPersonal")
          .then(({ data }) => {
            context.commit(SET_PERSONAL_INFO, data.data);
            resolve(data.data);
          })
          .catch(({ response }) => {
            context.commit(SET_ERROR, response.data.errors);
          });
    });
    // context.commit(SET_PERSONAL_INFO, payload);
  },
  [UPDATE_ACCOUNT_INFO](context, payload) {
    context.commit(SET_ACCOUNT_INFO, payload);
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_PERSONAL_INFO](state, user_personal_info) {

    state.user_personal_info = user_personal_info;
    state.user_personal_info = {
      id: user_personal_info.id,
      photo: user_personal_info.image,
      name: user_personal_info.first_name,
      surname: user_personal_info.last_name,
      company_name: user_personal_info.company_name,
      job: user_personal_info.job,
      email: user_personal_info.email,
      phone: user_personal_info.phone,
      company_site: user_personal_info.company_site

    };
  },
  [SET_ACCOUNT_INFO](state, user_account_info) {
    state.user_account_info = user_account_info;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
